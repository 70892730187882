/* =============================================================
 * import
 * ========================================================== */
import parallax from '../modules/parallax';

/* =============================================================
 * function
 * ========================================================== */
export default function exe() {
  parallax();
}
