/* =============================================================
 * import
 * ========================================================== */
// import * as scrollObserver from './lib.scrollObserver';
// import {mediaQuery} from './jquery.common';
import $ from 'jquery';
import bowser from 'bowser';

const browser = bowser.getParser(window.navigator.userAgent);


/* =============================================================
 * export function
 * ========================================================== */
export function before() {

  const $body = document.body;
  // const path = location.pathname;

  $body.classList.remove('top_page');
  $body.classList.add('sub_page');
  // globalNavi(path);
}

export function cursor() {

  if (!browser.is('mobile') && !browser.is('tablet')) {

    const cursor = document.getElementById('cursor');

    // leader
    const leaderID = 'cursor_leader';
    if (document.getElementById(leaderID) == null) {
      const leader = document.createElement('span');
      leader.id = leaderID;
      leader.className = leaderID;
      cursor.appendChild(leader);
    }

    // leader_filled
    const filledID = 'cursor_filled';
    if (document.getElementById(filledID) == null) {
      const filled = document.createElement('span');
      filled.id = filledID;
      filled.className = filledID;
      cursor.appendChild(filled);
    }

    document.addEventListener('mousemove', function(e) {

      // 擬似カーソルの位置調整
      cursor.style.transform = 'translate(' + e.clientX + 'px, ' + e.clientY + 'px)';

    });
  }
}

export function cursorAnimation() {

  if (!browser.is('mobile') && !browser.is('tablet')) {
    const cursor = document.getElementById('cursor');
    const linkElem = document.querySelectorAll('a:not(.cursor_circle), .cursor_dot');
    for (let i = 0; i < linkElem.length; i++) {
      linkElem[i].addEventListener('mouseover', function() {
        cursor.classList.add('active');
      });
      linkElem[i].addEventListener('mouseout', function() {
        cursor.classList.remove('active');
      });
    }

    const linkElemCircle = document.querySelectorAll('.cursor_circle');
    for (let i = 0; i < linkElemCircle.length; i++) {
      linkElemCircle[i].addEventListener('mouseover', function() {
        cursor.classList.add('active_circle');
      });
      linkElemCircle[i].addEventListener('mouseout', function() {
        cursor.classList.remove('active_circle');
      });
    }
  }
}

export function after() {
  slideDownBtn();
  slideToggleBtn();
  toTop();
}


/* =============================================================
 * function
 * ========================================================== */
function slideDownBtn() {
  const btn = $("#slideDownBtn");
  const area = $("#slideDownArea");
  if (btn.length) {
    btn.click(function() {
      btn.toggleClass('open');
      area.toggleClass('open').slideToggle();
    });
  }
}

function slideToggleBtn() {
  const btn = $(".slideBtn");
  const area = ".slideCnt";
  if (btn.length) {
    btn.click(function() {
      $(this).stop().toggleClass('is-open');
      $(this).next(area).stop().slideToggle();
    });
  }
}

function toTop() {
  const btn = $("#totop");
  if (btn.length) {
    btn.click(function() {
      $("html, body").animate({
        scrollTop: 0,
      }, 600, "swing");
      return false;
    });
  }
}