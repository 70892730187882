/* =============================================================
 * import
 * ========================================================== */
import {mediaQuery} from './common';
import $ from "jquery";
import {eventObserver} from "./eventObserver";

/* =============================================================
 * globalNavi
 * グローバルナビに関する実行関数
 * ========================================================== */
export function globalNavi() {
  const windowWidth = window.innerWidth;
  const listElements = document.querySelectorAll('.header_gnav_child_list_item');
  const listElementsChildTop = document.querySelectorAll('a.header_gnav_top_list_item_link');
  const listElementsChildBottom = document.querySelectorAll('a.header_gnav_bottom_list_item_link');
  const parentElements = document.querySelector('.gnav_list_parent');
  const childElements = parentElements.querySelector('.header_gnav_children');
  const hum = document.getElementById('hum');
  const logo = document.getElementById('header_logo');
  const contactBtn = document.getElementById('header_contact');
  const header = document.getElementById('header');
  const mainContents = document.getElementById('main');
  const hasTapEvent = ('ontouchstart' in window);

  const objGlobalNaviLinkToggle = {
    elem: parentElements,
    handleEvent: globalNaviLinkToggle,
  };

  const objGlobalNaviLinkEnter = {
    elem: parentElements,
    handleEvent: globalNaviLinkEnter,
  };

  const objGlobalNaviLinkLeave = {
    elem: parentElements,
    handleEvent: globalNaviLinkLeave,
  };


  if (mediaQuery(windowWidth) !== 'sp') {
    /* ----- PC ----- */

    // MouseEnter/Leave
    if (parentElements) {
      if (hasTapEvent === true) {
        parentElements.addEventListener('click', objGlobalNaviLinkToggle, false);
      } else {
        parentElements.addEventListener('mouseenter', objGlobalNaviLinkEnter, false);
        parentElements.addEventListener('mouseleave', objGlobalNaviLinkLeave, false);
      }
    }

    if (childElements) {
      childElements.style.visibility = 'hidden';
    }

    // headerのサイズ調整
    mainContents.style.paddingTop = header.offsetHeight + 'px';
    window.addEventListener('resize', function() {
      mainContents.style.paddingTop = header.offsetHeight + 'px';
    });

  } else {
    /* ----- SP ----- */
    if (hum) {
      // SP HumbergerMneu
      hum.addEventListener('click', humberger);
      logo.addEventListener('click', humberger);
      contactBtn.addEventListener('click', humberger);
      listElements.forEach((elem) => {
        elem.addEventListener('click', humberger, false);
      });
      listElementsChildTop.forEach((elem) => {
        elem.addEventListener('click', humberger, false);
      });
      listElementsChildBottom.forEach((elem) => {
        elem.addEventListener('click', humberger, false);
      });
    }
    // サブカテゴリー開閉
    $("#nav_service_parent > .header_gnav_bottom_list_item_link").on('click.SpSubMenuBtn', function() {
      $(this).toggleClass('active');
      $(this).next(".header_gnav_children").stop().slideToggle(500).toggleClass('open');
      return false;
    });
  }

  // eventObserver
  const listeners = [
    [parentElements, 'click', objGlobalNaviLinkToggle, false],
    [parentElements, 'mouseenter', objGlobalNaviLinkEnter, false],
    [parentElements, 'mouseleave', objGlobalNaviLinkLeave, false],
  ];
  eventObserver.observeEvents(listeners);
}


/* =============================================================
 * humberger
 * ハンバーガーメニューの制御
 * ========================================================== */

export function humberger() {

  const html = document.documentElement;
  const hum = document.getElementById('hum');
  const gnav = document.getElementById('gnav');
  const filter = document.getElementById('filter');
  
  if (hum) {

    if (hum.classList.contains('close')) {

      // 閉じている時
      if (!this.classList.contains('header_logo_link') && !this.classList.contains('header_offer_contact_link')) {
        hum.classList.remove('close');
        gnav.classList.remove('close');
        filter.classList.remove('close');
        hum.classList.toggle('open');
        gnav.classList.toggle('open');
        filter.classList.toggle('open');
        html.classList.toggle('is-fixed');
      }

    } else if (hum.classList.contains('open')) {

      // 開いている時
      hum.classList.add('close');
      gnav.classList.add('close');
      filter.classList.add('close');
      hum.classList.toggle('open');
      gnav.classList.toggle('open');
      filter.classList.toggle('open');
      html.classList.toggle('is-fixed');

    } else {
      // 初動
      if (!this.classList.contains('header_logo_link') && !this.classList.contains('header_offer_contact_link')) {
        hum.classList.toggle('open');
        gnav.classList.toggle('open');
        filter.classList.toggle('open');
        html.classList.toggle('is-fixed');
      }

    }

  }

}


/* =============================================================
 * globalNaviLinkEnter
 * グローバルナビへmouseenterした際の挙動
 * ========================================================== */
export function globalNaviLinkEnter() {
  let timerIdSub;
  let timerIdCurrent;
  const currentElem = document.querySelector('.header_gnav_list_open');

  if (this.elem.classList.contains('header_gnav_list_open')) {

    // .header_gnav_list_open を持っている場合は何もしない。

  } else if (this.elem.lastElementChild.classList.contains('header_gnav_children')) {

    // サブメニューの表示
    timerIdSub = this.elem.lastElementChild.dataset.timerId; // clearTimeout用のタイマーID
    if (timerIdSub) {
      // setTimeoutで遅延を入れているので、
      // 遅延中にenter処理が入った際に上書きができず、
      // 以下のsetTimeout処理が走らないため、clearTimeoutで解消。
      clearTimeout(timerIdSub);
    }
    this.elem.lastElementChild.style.visibility = 'visible';
    timerIdSub = setTimeout(() => {
      this.elem.classList.add('open');
      this.elem.firstElementChild.classList.add('open');
      this.elem.lastElementChild.classList.add('open');
    }, 100);
    this.elem.lastElementChild.dataset.timerId = timerIdSub; // タイマーIDが引き渡せないので、data属性に格納

    // 現在表示中（該当ページ）のサブメニュー
    if (currentElem) {
      if (currentElem.lastElementChild.classList.contains('header_gnav_children')) {
        timerIdCurrent = currentElem.lastElementChild.dataset.timerId;
        if (timerIdCurrent) {
          clearTimeout(timerIdCurrent);
        }

        currentElem.firstElementChild.classList.remove('open');
        currentElem.lastElementChild.classList.remove('open');
        timerIdCurrent = setTimeout(() => {
          currentElem.lastElementChild.style.visibility = 'hidden';
        }, 200);
        currentElem.lastElementChild.dataset.timerId = timerIdCurrent; // タイマーIDが引き渡せないので、data属性に格納
      }
      currentElem.classList.add('tmp_close');
    }

  } else {
    if (currentElem) {
      currentElem.classList.add('tmp_close');
    }
  }
}


/* =============================================================
 * globalNaviLinkLeave
 * グローバルナビからmouseleaveした際の挙動
 * ========================================================== */
export function globalNaviLinkLeave() {
  let timerIdSub;
  const currentElem = document.querySelector('.header_gnav_parent_list_open');

  if (this.elem.classList.contains('header_gnav_parent_list_open')) {

    // .header_gnav_parent_list_open を持っている場合は何もしない。

  } else if (this.elem.lastElementChild.classList.contains('header_gnav_children')) {
    // サブメニューの非表示
    timerIdSub = this.elem.lastElementChild.dataset.timerId; // clearTimeout用のタイマーID
    if (timerIdSub) {
      clearTimeout(timerIdSub);
    }

    this.elem.classList.remove('open');
    this.elem.firstElementChild.classList.remove('open');
    this.elem.lastElementChild.classList.remove('open');
    timerIdSub = setTimeout(() => {
      this.elem.lastElementChild.style.visibility = 'hidden';

    }, 200);
    // mouseenterの方でもsetTimeoutで遅延を入れているので、
    // timerIdを引き渡して遅延中にenter処理が入った際にキャンセルを挿入。
    this.elem.lastElementChild.dataset.timerId = timerIdSub; // タイマーIDが引き渡せないので、data属性に格納

  } else {
    if (currentElem) {
      currentElem.classList.remove('tmp_close');
    }
  }
}


/* =============================================================
 * globalNaviLinkToggle
 * グローバルナビをクリックした際の挙動
 * ========================================================== */
export function globalNaviLinkToggle() {
  let timerIdSub;

  if (this.elem.classList.contains('open')) {
    // .open を持っている場合、閉じる。

    // サブメニューの非表示
    timerIdSub = this.elem.lastElementChild.dataset.timerId; // clearTimeout用のタイマーID
    if (timerIdSub) {
      clearTimeout(timerIdSub);
    }

    this.elem.classList.remove('open');
    this.elem.firstElementChild.classList.remove('open');
    this.elem.lastElementChild.classList.remove('open');
    timerIdSub = setTimeout(() => {
      this.elem.lastElementChild.style.visibility = 'hidden';

    }, 200);
    // mouseenterの方でもsetTimeoutで遅延を入れているので、
    // timerIdを引き渡して遅延中にenter処理が入った際にキャンセルを挿入。
    this.elem.lastElementChild.dataset.timerId = timerIdSub; // タイマーIDが引き渡せないので、data属性に格納

  } else {
    // .open を持っていない場合、開ける。

    // サブメニューの表示
    timerIdSub = this.elem.lastElementChild.dataset.timerId; // clearTimeout用のタイマーID
    if (timerIdSub) {
      // setTimeoutで遅延を入れているので、
      // 遅延中にenter処理が入った際に上書きができず、
      // 以下のsetTimeout処理が走らないため、clearTimeoutで解消。
      clearTimeout(timerIdSub);
    }
    this.elem.lastElementChild.style.visibility = 'visible';
    timerIdSub = setTimeout(() => {
      this.elem.classList.add('open');
      this.elem.firstElementChild.classList.add('open');
      this.elem.lastElementChild.classList.add('open');
    }, 100);
    this.elem.lastElementChild.dataset.timerId = timerIdSub; // タイマーIDが引き渡せないので、data属性に格納

  }
}


/* =============================================================
 * checkParentDirectory
 * 現在のディレクトリ名からサブページの有無を判定
 * ========================================================== */
export function checkParentDirectory(path) {
  let reg;
  let result;
  let hasChildDir = false;

  // 表示中のページ判定
  const childPathArray = ['/service/', '/works/', '/company/', '/about/', '/media/', '/contact/'];
  childPathArray.forEach(dir => {
    reg = new RegExp(dir);
    result = path.match(reg);
    if (result != null) {
      hasChildDir = true;
      return;
    }
  });
  return hasChildDir;
}

