/* =============================================================
 * import
 * ========================================================== */
import parallax from '../modules/parallax';
import $ from "jquery";
import 'slick-carousel';

/* =============================================================
 * function
 * ========================================================== */
export default function exe() {
  parallax();
  showContents();
  gallerySlider();
}

function showContents() {

  const btn = $("#show-room_list_item");
  const contents = $('.room_list_item.invisible');
  if( btn.length ) {
    btn.on('click', function() {
      btn.fadeOut();
      contents.fadeIn();
    });
  }

}

function gallerySlider() {
  if ($("#gallerySlider").length) {
    $('#gallerySlider').slick({
      asNavFor: '#gallerySliderThumb',
      fade: true,
      autoplay: false,
      speed: 600,
      autoplaySpeed: 2800,
      dots: false,
      swipe: true,
      swipeToSlide: false,
      touchMove: true,
      pauseOnHover: true,
      draggable: true,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      centerMode: false,
      variableWidth: false,
      arrows: true,
      prevArrow: '<div class="arw_prev">',
      nextArrow: '<div class="arw_next">',
    });
    $('#gallerySliderThumb').slick({
      asNavFor: '#gallerySlider',
      focusOnSelect: true,
      autoplay: false,
      speed: 600,
      autoplaySpeed: 2800,
      dots: false,
      swipe: true,
      swipeToSlide: false,
      touchMove: true,
      pauseOnHover: false,
      draggable: true,
      infinite: true,
      slidesToShow: 5,
      slidesToScroll: 1,
      centerMode: false,
      variableWidth: false,
      arrows: false,
    });
  }
}

