/* =============================================================
 * import
 * ========================================================== */
import ScrollHint from 'scroll-hint';
import $ from 'jquery';

/* =============================================================
 * function
 * ========================================================== */

export default function exe() {
  tableScrollHint();
  searchSelect();

  window.addEventListener('resize', function() {
    tableScrollHint();
  });
}

function tableScrollHint() {
  // wrap table settings
  const contentsArea = document.querySelectorAll(".contents_post table");
  if (contentsArea !== null) {
    const tableAry = Array.prototype.slice.call(contentsArea);
    tableAry.forEach(function(e) {
      e.outerHTML = '<div class="table_wrap">' + e.outerHTML + '</div>';
    });
  }

  // scrollableJS settings
  const scrollableTrigger = document.querySelectorAll(".table_wrap");
  if (scrollableTrigger !== null) {
    const scrollableAry = Array.prototype.slice.call(scrollableTrigger);
    scrollableAry.forEach(function() {
      new ScrollHint(scrollableAry, {
        scrollHintIconAppendClass: 'scroll-hint-icon-white',
        // applyToParents: true,
        i18n: {
          scrollable: 'スクロールできます',
        },
      });
    });
  }
}

function searchSelect() {
  if ($('#select_date').length) {
    const $selectDate = $('#select_date');

    // 年月の選択が発生したタイミングで、検索用のデータhidden項目にデータを挿入
    $selectDate.on('change', function() {
      const optionVal = $(this).val();
      const optionValDate = optionVal.match(/^(https|http):\/\/([a-z]{1,}\.|)rihee\.com\/(.*?)\/(.*?)\//);
      if (optionValDate) {
        $("#search_year").val(optionValDate[3]);
        $("#search_month").val(optionValDate[4]);
      } else {
        $("#search_year").val('');
        $("#search_month").val('');
      }
    });

    // 年月の絞り込みが発生していた場合、年月の選択肢をselectedに設定
    if ($('#search_year').length || $('#search_month').length) {
      const searchYear = $('#search_year').val();
      const searchMonth = $('#search_month').val();

      if (searchYear && searchMonth) {
        $selectDate.children('option[value="' + location.protocol + '//' + location.hostname + '/' + searchYear + '/' + searchMonth + '/?post_type=news"]').prop('selected', true);
      }
    }
  }
}