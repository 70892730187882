/* =============================================================
 * import
 * ========================================================== */

/* =============================================================
 * function
 * ========================================================== */

export default function parallax() {
  const pax = document.getElementsByClassName('pax');
  let scrolled = false;

  window.addEventListener('scroll', function() {
    if (scrolled) return;
    scrolled = true;

    setTimeout(() => {
      scrolled = false;
    }, 100);

    // const windowTop = window.pageYOffset;
    const WindowBottom = window.innerHeight + window.pageYOffset;
    // const scrolls = window.scrollY;

    [...pax].forEach(el => {
      const elmY = el.getBoundingClientRect().top + window.pageYOffset;
      const elmBottom = elmY + el.clientHeight;
      if (elmY < WindowBottom && elmBottom > window.pageYOffset) {  // 要素の一部が画面内にある
        const elmHeight = WindowBottom - elmY;
        el.animate({
          transform: [el.style.transform, `translateY(${elmHeight * el.dataset.pax * -1}px)`],
        }, {
          duration: 200,
          fill: 'forwards',
        });
      }
    });
  });
}

export function parallaxHorizon() {
  const pax = document.getElementsByClassName('pax_horizon');
  let scrolled = false;

  window.addEventListener('scroll', function() {
    if (scrolled) return;
    scrolled = true;

    setTimeout(() => {
      scrolled = false;
    }, 100);

    // const windowTop = window.pageYOffset;
    const WindowBottom = window.innerHeight + window.pageYOffset;
    // const scrolls = window.scrollY;

    [...pax].forEach(el => {
      const elmY = el.getBoundingClientRect().top + window.pageYOffset;
      const elmBottom = elmY + el.clientHeight;
      if (elmY < WindowBottom && elmBottom > window.pageYOffset) {  // 要素の一部が画面内にある
        const elmHeight = WindowBottom - elmY;
        el.animate({
          transform: [el.style.transform, `translateX(${elmHeight * el.dataset.pax * -1}px)`],
        }, {
          duration: 200,
          fill: 'forwards',
        });
      }
    });
  });
}
