/* =============================================================
 * import
 * ========================================================== */
import * as pax from "../modules/parallax";

/* =============================================================
 * function
 * ========================================================== */
export default function exe() {
  pax.default();
  pax.parallaxHorizon();
}
