/* =============================================================
 * import
 * ========================================================== */
import parallax from '../modules/parallax';
import $ from "jquery";

/* =============================================================
 * function
 * ========================================================== */
export default function exe() {
  kvSlider(0);
  inPageScroll();
  parallax();

  window.addEventListener('resize', function() {
    kvSlider(0);
    inPageScroll('resize');
  });

}


function kvSlider(delay = 4000) {
  const slider = document.querySelectorAll('.kv_slider');

  // sliderOption
  const opt = {
    targetElm: slider,    // 対象要素のクラス名
    className: 'active',  // 付与するクラス名
    firstDelay: delay,     // 初めのクラス付与までの遅延時間
    duration: 6000,       // 間隔
    startImmediate: true, // 初期状態から1つ目に付与するのか
    oneWay: false,        // 付け替えじゃなくて、片道だけ付けて終わりたいか
  };

  classCycler(opt);
}

function classCycler(opt) {

  let index = 0;
  let timer;
  const item = opt.targetElm;

  if (opt.startImmediate) {
    item[index].classList.add(opt.className);
  }

  const execCycle = () => {
    const max = item.length;

    if (!opt.oneWay) {
      item[index].classList.remove(opt.className);
    }

    index = (++index === max) ? 0 : index;
    item[index].classList.add(opt.className);

    if (opt.oneWay && index === 0) {
      clearInterval(timer);
    }
  };

  setTimeout(function() {
    timer = setInterval(execCycle, opt.duration);
  }, opt.firstDelay);

}

function inPageScroll(event) {
  const btn = $("#kv_scroll");
  const targetElement = document.getElementById("top_news");

  if (event == 'resize') {
    btn.off('click');
  }

  if (btn.length) {
    btn.on('click', function() {
      const targetPosition = targetElement.getBoundingClientRect();
      const position = targetPosition.top + window.scrollY;
      $("html, body").animate({
        scrollTop: position,
      }, 800, "jswing");
      return false;
    });
  }
}



