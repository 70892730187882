/* =============================================================
 * import
 * ========================================================== */
import barba from '@barba/core';
import barbaPrefetch from '@barba/prefetch';
import {gsap} from 'gsap';
import {CustomEase} from "../../../../packages/gsap/CustomEase";
import * as barbaOpt from './options';
import * as scrollObserver from '../modules/scrollObserver';
import * as routeCommon from '../route/common';
import top from '../route/top';
import company from '../route/company';
import pm from '../route/pm';
import recruit from '../route/recruit';
import rentalOffice from '../route/rentalOffice';
import news from '../route/news';
import contact from '../route/contact';
import {globalNavi} from '../modules/gnav';
import {modalWindow} from '../modules/common';

/* =============================================================
 * variables
 * ========================================================== */

// アニメーション用の変数定義
const $body = document.body;
const domain = document.domain;
const tt = document.querySelectorAll('.tt_layer');
const $header = document.getElementById('header');
const winH = window.innerHeight;
gsap.registerPlugin(CustomEase);
const easeTransition = CustomEase.create("easeTransition", "M0,0 C0.376,0 0.162,1 1,1");

export default function barbaRun() {

  barba.use(barbaPrefetch);
  barba.init({
    debug: true,
    logLevel: 'debug',
    schema: {
      prefix: 'data-trans',
      wrapper: 'wrapper',
      container: 'container',
      namespace: 'namespace',
    },
    prevent: ({el, event}) => barbaOpt.customPreventCheck(el, event),
    // キャッシュを無効にするページ指定
    cacheIgnore: [
      '/contact/',
      '/contact/confirmation/',
      '/contact/thanks/',
    ],
    views: [
      {
        namespace: 'home',
        afterEnter(data) { // eslint-disable-line @typescript-eslint/no-unused-vars
          // routeTop.sliders();
          top();
        },
      },
      {
        namespace: 'company',
        afterEnter(data) { // eslint-disable-line @typescript-eslint/no-unused-vars
          company();
        },
      },
      {
        namespace: 'pm',
        afterEnter(data) { // eslint-disable-line @typescript-eslint/no-unused-vars
          pm();
        },
      },
      {
        namespace: 'recruit',
        afterEnter(data) { // eslint-disable-line @typescript-eslint/no-unused-vars
          recruit();
        },
      },
      {
        namespace: 'rental-office',
        afterEnter(data) { // eslint-disable-line @typescript-eslint/no-unused-vars
          rentalOffice();
        },
      },
      {
        namespace: 'news',
        afterEnter(data) { // eslint-disable-line @typescript-eslint/no-unused-vars
          news();
        },
      },
      {
        namespace: 'contact',
        afterEnter(data) { // eslint-disable-line @typescript-eslint/no-unused-vars
          contact();
        },
      },
    ],

    transitions: [
      {
        // 全ページ共通のトランジション
        name: 'global-transitions',
        sync: false, // 同時実行モード
        leave(data) {
          const scrollTop = window.scrollY;

          // 旧コンテンツを固定
          gsap.set(data.current.container, {
            position: 'fixed',
            top: -scrollTop,
          });

          // トランジション開始時の共通処理を実行
          ttStart(data);

          /*
          await new Promise((resolve) => {
            return setTimeout(resolve, 100);
          });
          */

        },
        async beforeEnter(data) {
          // 新旧コンテンツ混合
          ttBeforeEnter(data);

          // 旧コンテンツの動き
          gsap.fromTo(data.current.container,
            {
              y: 0,
              zIndex: 0,
            },
            {
              y: -160,
              ease: easeTransition,
              delay: 0,
              duration: .9,
            },
          ), gsap.fromTo(data.next.container,
            {
              y: winH,
              zIndex: 10,
            },
            {
              y: 0,
              ease: easeTransition,
              delay: 0.1,
              duration: .8,
            },
          );
          // beforeEnter内でのアニメーション実行が完了するまで待って、次のステップに進む
          await new Promise((resolve) => {
            return setTimeout(resolve, 900);
          });
        },
        after() {
          ttEnd();
          setTimeout(() => {
            gsap.to(tt, {
              duration: .5,
              yPercent: 100,
            });
          }, 0);
        },
      },
      /*
      {
        // 各ルールに応じた個別トランジションの設定
        name: 'custom-transitions',
        to: { // どのページへの遷移か
          namespace: [
            'company',
          ],
        },
        from: { // どのページからの遷移か
          namespace: [
            'home',
          ],
        },

        async leave(data) {
          console.log(data);
        },

        async after(data) {
          console.log(data);
        },
      },
      */
    ],

  });

  /*
   * Transitions
   *  beforeOnce, once, afterOnce, before,
   *  beforeLeave, leave, afterLeave, afterLeave,
   *  enter, afterEnter, after
   * Views
   *  beforeLeave, afterLeave, beforeEnter, afterEnter
   * */

}

// トランジション開始時の設定
function ttStart(data) {
  barbaOpt.resetAnyFunc(data);  // 各イベント処理をリセット
  barbaOpt.scrollAdjuster();    // スクロール位置を調整
  barbaOpt.samePageNotReload();
  $body.classList.remove('loaded');
  $body.classList.add('loading');
  $header.classList.remove('scrolled');
  $header.style.pointerEvents = 'none';
}

function ttBeforeEnter(data) {
  barbaOpt.replaceHeadTags(data.next);
  routeCommon.cursor();
  routeCommon.cursorAnimation();
  if (domain === 'rihee.com') {
    const gaArg = {
      type: 'gtag',
      path: data.next.url.path,
      account: [
        'G-7C0ZSB6E7X',
      ],
    };
    barbaOpt.gaSend(gaArg);
  }
  // barbaOpt.snsBtnReload(data.next.url.href);
  routeCommon.before();
  globalNavi();
  data.next.container.classList.add('tt_enter');
  modalWindow();
}

// トランジション終了時の設定
function ttEnd() {
  scrollObserver.floating();
  $body.classList.remove('loading');
  $body.classList.add('loaded');
  $header.style.pointerEvents = 'auto';
  routeCommon.after();
}

